<template>
  <tr>
    <td class="px-2">
      <label-icon :type="props.item.type" />
    </td>
    <td>{{ props.item.title }}</td>
    <td class="text-center">
      {{ props.item.start }}
    </td>
    <td class="text-center">
      {{ $t('task.state.' + props.item.state) }}
    </td>
    <td class="text-right px-2">
      <v-menu
        bottom
        right
        offset-y
        :disabled="setDisabled(props.item.state)">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="setDisabled(props.item.state)"
            v-on="on">
            <v-icon color="darkGray">
              {{ $root.icons.layout.more_y }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$emit('openModalComplete', props.item)">
            <v-list-item-title>Finalizar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('openModalCancel', props.item)">
            <v-list-item-title>Cancelar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('openModalPostpon', props.item)">
            <v-list-item-title>Adiar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>

<script>
  import LabelIcon from '@/components/ui/LabelIcon'

  export default {
    components: { LabelIcon },
    props: {
      props: Object
    },
    methods: {
      setDisabled (state) {
        return state !== 'scheduled'
      }
    }
  }
</script>

<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-card-text class="pt-0 pl-0">
        <h1 class="main-title">
          Agenda
        </h1>

        <v-layout fill-height>
          <v-flex
            v-for="item of tasks"
            :key="item.id"
            xs12
            md3
            sm12>
            <v-card
              class="campaign-item ma-2"
              :to="{ name: 'EventInfo', params: { eventId: item.id } }"
              flat
              ripple>
              <v-card-title>{{ item.start }} </v-card-title>
              <!-- <v-card-subtitle>{{ $t('event.type.' + item.type) }} - {{ $t('event.procedure.' + item.procedure) }}</v-card-subtitle> -->
              <v-divider class="mx-4" />
              <!-- <v-card-text>
                <v-list dense flat>
                  <v-list-item class="pa-0">
                    <v-icon small color="primary" :disabled="!item.proposal.has">
                      check_circle
                    </v-icon>
                    <span>Proposta Initial</span>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-icon small color="primary" :disabled="!item.negotiation.has">
                      check_circle
                    </v-icon>
                    <span>Negociação</span>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-icon small color="primary" :disabled="!item.mediators.has">
                      check_circle
                    </v-icon>
                    <span>Mediação</span>
                  </v-list-item>
                </v-list>
              </v-card-text> -->
              <!-- <v-card-actions>
                <v-chip class="ml-2 font-weight-bold" small :disabled="!item.email.send">
                  Email
                </v-chip>
                <v-chip class="ml-2 font-weight-bold" small :disabled="!item.sms.send">
                  Sms
                </v-chip>
                <v-chip class="ml-2 font-weight-bold" small :disabled="!item.letter.send">
                  Carta
                </v-chip>
              </v-card-actions> -->
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <transition
      name="slide"
      mode="out-in"
      appear>
      <router-view />
    </transition>

    <modal
      :show="showModalComplete"
      btn-primary-label="Sim"
      btn-secondary-color="Não"
      max-width="400px"
      @close="closeModalComplete"
      @submit="saveModalComplete">
      <span slot="title">Finalização</span>
      <div slot="content">
        <p class="mt-3">
          Deseja que a tarefa <b>{{ scheduleSelected.title }}</b> seja finalizada?
        </p>
      </div>
    </modal>

    <modal
      :show="showModalPostpon"
      max-width="720px"
      @close="closeModalPostpon"
      @submit="saveModalPostpon">
      <span slot="title">Adiamento</span>
      <v-form
        slot="content"
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-layout
          row
          wrap>
          <v-flex
            xs12
            sm12
            class="pt-0 px-0">
            <date-time-range @update="updateDateTime" />
          </v-flex>
        </v-layout>
      </v-form>
    </modal>

    <modal
      :show="showModalCancel"
      max-width="400px"
      submit-label="Confirmar"
      @close="closeModalCancel"
      @submit="saveModalCancel">
      <span slot="title">Cancelamento</span>
      <div slot="content">
        <p class="mt-3">
          Deseja que a tarefa <b>{{ scheduleSelected.title }}</b> seja cancelada?
        </p>
      </div>
    </modal>
  </v-container>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import scheduleMixin from '@/mixins/schedule'
  import { mapGetters } from 'vuex'

  export default {
    components: { AppList, Row },
    mixins: [ scheduleMixin ],
    data () {
      const today = new Date().toLocaleDateString('fr-CA')

      return {
        loading: 0,
        today: today,
        focus: today,
        type: 'month',
        typeToLabel: {
          month: 'Mês',
          week: 'Semana',
          day: 'Dia',
          '4day': '4 Dias',
        },
        start:null,
        end: null,
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        isOwner: false
      }
    },
    computed: {
      ...mapGetters({
        tasks: 'tasks/list',
        userId: 'user/id'
      }),

      title () {
        const { start, end } = this
        if (!start || !end) { return '' }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
      formattedTasks () {
        return this.tasks.map(task => {
          task.start = this._formatDate(task.start)
          task.end = this._formatDate(task.end)

          return task
        })
      }
    },
    watch: {
      selectedEvent () {
        this.isOwner = this.userId === this.selectedEvent.ownerId
      }
    },
    beforeMount () {
      this.$store.dispatch('tasks/getList')
    },
    methods: {
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (task) {
        const colors = {
          video: this.$vuetify.theme.themes.light.primary,
          phone: this.$vuetify.theme.themes.light.secondary,
          task: this.$vuetify.theme.themes.light.accent
        }

        return colors[task.type]
      },
      getTaskIcon (task) {
        const icons = {
          video: this.$root.icons.layout.video,
          phone: this.$root.icons.layout.phone,
          task: this.$root.icons.layout.calendar
        }

        return icons[task.type]
      },
      setToday () {
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange ({ start, end }) {
        this.start = start
        this.end = end
      },
      nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      _formatDate(event) {
        let [date, time] = event.split(' ')
        date = date.split('/').reverse().join('-')
        return `${date} ${time}`
      }
    }
  }
</script>
